// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zo2mhsnpqidV_fafhxGQ{font-weight:bold}.YgHjuDxEqwiBQQHkENme .Ey49FH4jCiqSY1DJAl2g{font-size:20px}.YgHjuDxEqwiBQQHkENme .gklPU7nwW7JjRH0kYRWw{padding-left:15px}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/ServicesTab/components/FeaturesForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEA,4CACE,cAAA,CACF,4CACE,iBAAA","sourcesContent":[".mainCheckbox\n  font-weight: bold\n.feature\n  .title\n    font-size: 20px\n  .content\n    padding-left: 15px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainCheckbox": `Zo2mhsnpqidV_fafhxGQ`,
	"feature": `YgHjuDxEqwiBQQHkENme`,
	"title": `Ey49FH4jCiqSY1DJAl2g`,
	"content": `gklPU7nwW7JjRH0kYRWw`
};
export default ___CSS_LOADER_EXPORT___;
